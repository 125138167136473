import { fetchMarketingData } from '@core/contentful/fetchMarketingData';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

// UI
import DefaultLayout from '@ui/layouts/Default.layout';
import TiltScreen from '@ui/components/TiltScreen';
import Headline from '@ui/components/Headline';
import Subtitle from '@ui/components/Subtitle';
import MetaTags from '@ui/components/MetaTags';

// Types
import { NextPage } from 'next';

export const NotFound: NextPage<any> = ({}) => {
    const { t } = useTranslation('common');
    const { locale, pathname } = useRouter();

    return (
        <>
            <MetaTags
                title={t('404-title')}
                description={t('404-description')}
                locale={locale}
                pathname={pathname}
            />

            <DefaultLayout>
                <div className="mx-auto flex max-w-screen-md flex-col space-y-12 md:flex-row md:space-x-12 md:space-y-0">
                    <TiltScreen title={'404'} />

                    <div className="flex flex-1 flex-col items-center justify-center text-center md:items-start md:text-left">
                        <Headline align="left">{t('404-title')}</Headline>
                        <Subtitle align="left">{t('404-description')}</Subtitle>
                    </div>
                </div>
            </DefaultLayout>
        </>
    );
};

export default NotFound;

export const getStaticProps = async ({ locale }) => {
    const response = await fetchMarketingData(locale);

    return {
        props: {
            ...(await serverSideTranslations(locale, ['common'])),
            // Will be passed to the page component as props
        },
    };
};
